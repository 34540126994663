/* DeviceInfo.css */

.device-info {
  font-family: 'Aktiv Grotesk', Arial, sans-serif;
  margin-top: 0px;
  margin-bottom: 15px;
}

.device-list {
  padding-left: 20px; /* Add padding to indent the list */
  list-style-type: disc;
  line-height: 0.8;
}

.device-item {
  font-size: 1em;
  margin-bottom: 10px;
}

.device-detail {
  font-size: 0.75em;
  line-height: 0.4;
}

.device-detail.meta {
  color: rgb(116, 132, 148); /* Set the color for other metadata */
}