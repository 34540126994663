/* UserInfo.css */

.user-info {
  font-family: "Helvetica", Arial, sans-serif;
}

.user-name {
  font-size: 0.8em;
  margin-bottom: 0px;
  color: rgb(49, 67, 81);
  line-height: 0.8;
}

.account-list {
  list-style-type: disc;
  line-height: 0.8;
  margin-top: 0px;
  padding-left: 20px;
}

.account-item {
  font-size: 1em;
  margin-bottom: 10px;
}

.account-detail {
  font-size: 0.75em;
  line-height: 0.4;
}

.account-detail.meta {
  color: rgb(116, 132, 148);
}

.email-value {
  color: rgb(0, 119, 204);
}

.subscription-value {
  color: rgb(255, 145, 57);
}